import React from "react";
import "./PlansComponent.css";

const plansData = [
  {
    name: "Free",
    price: "Free",
    features: [
      "20 Spaces maximum",
      "Share space with 1 collaborator",
      "No AI feature",
    ],
    color: "#E8F5E9", // Light green background
    highlight: "#66BB6A", // Green accent
  },
  {
    name: "Basic",
    price: "$10/month",
    features: [
      "Unlimited spaces",
      "Share space with up to 5 collaborators",
      "AI feature included",
    ],
    color: "#E0F7FA", // Light turquoise background
    highlight: "#26C6DA", // Turquoise accent
  },
  {
    name: "Organization",
    price: "$30/month",
    features: [
      "Unlimited spaces",
      "Share space with up to 30 collaborators",
      "AI feature included",
    ],
    color: "#B2EBF2", // Slightly deeper turquoise background
    highlight: "#00ACC1", // Deeper turquoise accent
  },
];

const PlansComponent = () => {
  return (
    <div className="plans-component">
      <h1 className="plans-title">Choose Your Plan</h1>
      <div className="plans-grid">
        {plansData.map((plan, index) => (
          <div key={index} className="plan-card" style={{ backgroundColor: plan.color }}>
            <h2 className="plan-name" style={{ color: plan.highlight }}>
              {plan.name}
            </h2>
            <p className="plan-price">{plan.price}</p>
            <ul className="plan-features">
              {plan.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
            <button
              className="plan-button"
              style={{ backgroundColor: plan.highlight }}
            >
              Select
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlansComponent;
