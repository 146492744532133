import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const PublisherComment = ({ comment,onClickDisabled }) => {
    const [hideMore, setHideMore] = useState(true);

    return (
        <Typography className="desc-text" variant="body2" color="text.primary">
            {comment.length > 100 ? (
                hideMore ? (
                    <Typography >
                        {comment.slice(0, 100)}...
                        <Link
                            sx={{
                                cursor: 'pointer',
                                color: '#128d6f',
                                fontWeight: '700',
                                textDecorationColor: '#128d6f',
                            }}
                            onClick={() => !onClickDisabled&&setHideMore(false)}
                        >
                            Read More
                        </Link>
                    </Typography>
                ) : (
                    <>
                        <p>
                            <Typography paragraph sx={{ width: { sm: '90%' } }}>
                                {comment}
                            </Typography>
                            <Link
                                sx={{
                                    cursor: 'pointer',
                                    fontWeight: '700',
                                    color: '#128d6f',
                                    textDecorationColor: '#128d6f',
                                }}
                                onClick={() => setHideMore(true)}
                            >
                                Show Less
                            </Link>
                        </p>
                    </>
                )
            ) : (
                <>{comment}</>
            )}
        </Typography>
    );
};

export default PublisherComment;
