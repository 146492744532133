import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Grid, TextField, Autocomplete } from "@mui/material";
import { API_URL } from "../../consts";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useDispatch } from "react-redux";
import axios from "axios";
import { popupTimer } from "../../consts";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

const CreateSpace = ({ open, handleClose, onSpaceAdded }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    space_name: "",
    keywords: [],
  });
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [nameError, setNameError] = useState(false);

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    if (name === "space_name") {
      // Check if space_name exceeds the character limit
      if (value.length > 32) {
        setNameError(true);
      } else {
        setNameError(false);
        setForm({ ...form, [name]: value });
      }
    } else {
      setForm({ ...form, [name]: value });
    }
    setSubmit(false);
  };

  const handleFormSubmit = () => {
    setSubmit(true);
    if (form.space_name && form.space_name.length <= 32) {
      let url = API_URL + `/spaces`;
      axios
        .post(url, form)
        .then((res) => {
          setError(false);
          onSpaceAdded();
          setSubmit(false);
          setForm({
            space_name: "",
            keywords: [],
          });
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "New Space Added Successfully",
              type: "success",
            },
          });
          setTimeout(() => {
            dispatch({
              type: "SET_POPUP",
              payload: {
                text: "",
                type: "",
              },
            });
          }, popupTimer);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            dispatch({
              type: "SET_UNAUTHORIZED",
            });
            return;
          }
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: err.response.data.error
                ? err.response.data.error
                : "Something Went Wrong. Please Try Again Later",
              type: "error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: "SET_POPUP",
              payload: {
                text: "",
                type: "",
              },
            });
          }, popupTimer);
        });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style} className="signup-modal">
          <Typography id="transition-modal-title" variant="h6" component="h2">
            <CloseIcon
              className="cross-icon"
              onClick={() => {
                handleClose();
                setSubmit(false);
                setError(false);
                setForm({
                  space_name: "",
                  keywords: [],
                });
              }}
            />
            <div className="card-heading mb-81"></div>
          </Typography>
          <Box id="transition-modal-description">
            <Typography
              id="transition-modal-title"
              variant="h2"
              component="h2"
              sx={{ fontSize: "20px", textAlign: "center", fontWeight: "700" }}
            >
              Add your new space 
            </Typography>
            <TextField
              name="space_name"
              hiddenLabel
              type="text"
              fullWidth
              className="loginTextfields"
              id="standard-basic"
              onChange={handleFormChange}
              value={form.space_name}
              label="Space Name"
              variant="standard"
              error={(submit && form.space_name === "") || nameError}
              helperText={
                submit && form.space_name === ""
                  ? "Field is Required"
                  : nameError
                  ? "Space name cannot exceed 32 characters"
                  : ""
              }
              sx={{
                marginTop: "20px",
                borderColor: nameError ? "red" : "",
                "& .MuiInput-underline:before": {
                  borderBottomColor: nameError ? "red" : "",
                },
              }}
            />

            <Autocomplete
              multiple
              freeSolo
              id="tags-outlined"
              options={[]}
              defaultValue={[]}
              value={form.keywords}
              clearOnBlur={true}
              onChange={(e, newval, reason) => {
                setForm({ ...form, keywords: newval });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Add Keywords"
                  sx={{ marginTop: "20px" }}
                  onChange={() => setSubmit(false)}
                  onBlur={(e) => {
                    if (e.target.value !== "") {
                      setForm({
                        ...form,
                        keywords: form.keywords.concat(e.target.value),
                      });
                    }
                  }}
                />
              )}
            />
            <Typography
              paragraph
              sx={{ fontSize: "12px" }}
              className="textMuted"
            >
              Each Tag must contain at least two characters.
            </Typography>

            <Grid container sx={{ margin: "0 auto" }}>
              <Grid item sx={{ margin: "0 auto" }}>
                <Button
                  className="connectButton"
                  onClick={handleFormSubmit}
                  disabled={form.keywords.some((tag) => tag.length < 2)}
                  variant="contained"
                >
                  Add Space
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateSpace;
