import React, { useEffect, useState } from 'react';

// Function to escape special characters in RegExp
const escapeRegExp = (text) => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

// Function to split text into chunks of 3 words each
const splitTextIntoChunks = (text, chunkSize = 3) => {
  const words = text.split(/\s+/); // Split by spaces
  const chunks = [];

  for (let i = 0; i < words.length; i += chunkSize) {
    chunks.push(words.slice(i, i + chunkSize).join(' ')); // Join every 3 words into a chunk
  }

  return chunks;
};

// Function to render highlighted content safely without breaking the HTML structure
const HighlightedContent = ({ outerHTML, content }) => {
  const [highlightedContent, setHighlightedContent] = useState(outerHTML);

  useEffect(() => {
    if (outerHTML && content) {
      // Split content into chunks of 3 words
      const chunks = splitTextIntoChunks(content, 3);

      let updatedHTML = outerHTML;

      chunks.forEach((chunk) => {
        const escapedChunk = escapeRegExp(chunk.trim());
        if (escapedChunk) {
          // Create a regex to find the chunk and wrap it in <span> tags for highlighting
          const regex = new RegExp(escapedChunk, 'gi');
          updatedHTML = updatedHTML.replace(regex, (match) => `<span style="background-color: lightgreen;">${match}</span>`);
        }
      });

      setHighlightedContent(updatedHTML);
    }
  }, [outerHTML, content]);

  return <div dangerouslySetInnerHTML={{ __html: highlightedContent }} />;
};

// Main component to handle props and logic
const ItemContent = (props) => {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    if (props.data) {
      setData(props.data); // Ensure props data updates correctly
    }
  }, [props.data]);

  // If outer_html is not present, return the content directly
  if (!data.outer_html) {
    return <div>{data.content}</div>;
  }

  return (
    <div>
      <HighlightedContent outerHTML={data.outer_html} content={data.content} />
    </div>
  );
};

export default ItemContent;
