import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  FormControl,
  Input,
  InputLabel,
  Menu,
  MenuItem,
  Button
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import axios from "axios";
import { API_URL, popupTimer } from "../../consts";
import { useDispatch } from "react-redux";
import ConfirmationDialog from "../common/ConfirmationDialog";
import ChipList from "../common/ChipList";
import HomeCardItem from "./HomeCardItem";
import { useSelector } from "react-redux";
import ExtensionLoginSuccess from "../Extension/ExtensionLoginSuccess";
import { useNavigate } from "react-router-dom";

const CommunityRates = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [searhTags, setSearchTags] = useState([]);
  const [searchItem, setsearchItem] = useState("");
  const [filterBy, setFilterBy] = useState("all");
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [removedRateId, setRemovedRateId] = useState(null);
  let navigate = useNavigate();
  
  const topics = [
    "Climate Change",
    "Technology Innovations",
    "Artificial Intelligence",
    "Health and Wellness",
    "Mental Health",
    "Politics",
    "Economics",
    "Cryptocurrency",
    "Social Media",
    "Cybersecurity",
    "Education",
    "Sustainability",
    "Global Warming",
    "Space Exploration",
    "Renewable Energy",
    "Human Rights",
    "Science and Research",
    "Digital Transformation",
    "Data Privacy",
    "E-commerce",
    "Virtual Reality",
    "Food Security",
    "Travel and Tourism",
    "Entertainment Industry",
    "Workplace Dynamics",
    "Finance and Investment",
    "Cultural Trends",
    "Environmental Conservation",
    "Public Health",
    "Global Trade",
    "Sports",
    "Soccer"
]

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('yourItemKey');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  const deleteRate = () => {
    let url = API_URL + `/rates/${removedRateId}/delete`;
    axios.post(url, null)
      .then((res) => {
        getRatesRequest(null, filterBy, searhTags);
        setTimeout(() => {
          dispatch({ type: "SET_POPUP", payload: { text: "", type: "" } });
        }, popupTimer);
        dispatch({ type: "SET_POPUP", payload: { text: "Rate removed successfully", type: "success" } });
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          dispatch({ type: "SET_UNAUTHORIZED" });
          return;
        }
        dispatch({
          type: "SET_POPUP",
          payload: {
            text: err.response?.data?.error || "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({ type: "SET_POPUP", payload: { text: "", type: "" } });
        }, popupTimer);
      });
  };

  const onRateSuccessfully = (id) => {
    let url = API_URL + `/rates/${id}`;
    axios.get(url)
      .then((res) => {
        const ratedObj = res.data.rated_object.rate;
        setData(data.map((obj) => (obj.id === id ? ratedObj : obj)));
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          dispatch({ type: "SET_UNAUTHORIZED" });
          return;
        }
        dispatch({
          type: "SET_POPUP",
          payload: {
            text: err.response?.data?.error || "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({ type: "SET_POPUP", payload: { text: "", type: "" } });
        }, popupTimer);
      });
  };

  const token = useSelector((state) => state.token);

  useEffect(() => {
    getRatesRequest(null, filterBy, searhTags);
  }, []);

  useEffect(() => {
    if (localStorage.getItem('is_extension_login')) {
      navigate('/extension-login-success');
    }
  }, []);

  useEffect(() => {
    if (token && (filterBy || searhTags.length)) {
      getRatesRequest(null, filterBy, searhTags);
    }
  }, [filterBy, searhTags, token, selectedTopics]);

  const getRatesRequest = (search_query, search_type, tags) => {
    let url = API_URL + `/rates`;
    let paramsBody = {
      tags: tags.length > 0 ? tags.join(',') : null,
      topics: selectedTopics.length > 0 ? selectedTopics.join(',') : null,
    };
    axios.get(url, { params: paramsBody })
      .then((res) => setData(res.data.rates))
      .catch((err) => {
        if (err.response?.status === 401) {
          dispatch({ type: "SET_UNAUTHORIZED" });
          return;
        }
        dispatch({
          type: "SET_POPUP",
          payload: {
            text: err.response?.data?.error || "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({ type: "SET_POPUP", payload: { text: "", type: "" } });
        }, popupTimer);
      });
  };

  const onTagAdded = (e) => {
    if (e.key === "Enter") {
      setSearchTags([...searhTags, searchItem]);
      setsearchItem("");
    }
  };

  const handleSearchTagDelete = (index) => {
    setSearchTags(searhTags.filter((_, i) => i !== index));
  };

  const selectFromPrev = (item) => {
    setSearchTags([...searhTags, item]);
  };

  const handleTopicChange = (topic) => {
    setSelectedTopics((prev) => 
      prev.includes(topic) ? prev.filter((t) => t !== topic) : [...prev, topic]
    );
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenFilterMenu = (event) => setAnchorEl(event.currentTarget);

  const handleCloseFilterMenu = () => setAnchorEl(null);

  return (
    <Grid container spacing={2} style={{paddingTop: "34px", width: "100%" }}>
      <Grid item xs={12}>
        <h2>Community Reviews</h2>
        <FormControl className="searchbar" fullWidth variant="standard">
          <InputLabel htmlFor="standard-adornment-password">Search</InputLabel>
          <Input
            fullWidth
            onKeyDown={onTagAdded}
            className="searchBox"
            id="standard-adornment-password"
            value={searchItem}
            onChange={(e) => setsearchItem(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        <ChipList list={searhTags} onDelete={handleSearchTagDelete} />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={handleOpenFilterMenu} startIcon={<TuneIcon />}>
          Filter by Topics
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseFilterMenu}>
          {topics.map((topic) => (
            <MenuItem key={topic}>
              <Checkbox
                checked={selectedTopics.includes(topic)}
                onChange={() => handleTopicChange(topic)}
              />
              {topic}
            </MenuItem>
          ))}
        </Menu>
      </Grid>
      {data.map((m, index) => (
        <Grid item xs={12} sm={2} md={4} key={index} >
         <div style={{height:'400px',maxWidth:'420px'}}> <HomeCardItem
            isBookmark={true}
            data={m}
            searhTags={searhTags}
            applyMargin={true}
            showRemove={false}
            onRemove={(id) => {
              setRemoveDialogOpen(true);
              setRemovedRateId(id);
            }}
            onRateSuccessfully={onRateSuccessfully}
          />
          </div>
        </Grid>
      ))}
      <ConfirmationDialog
        open={removeDialogOpen}
        onClose={() => setRemoveDialogOpen(false)}
        onConfirm={() => {
          deleteRate();
          setRemoveDialogOpen(false);
        }}
        title={"Delete rate"}
        cancelButtonString={"Cancel"}
        confirmButtonString={"Delete"}
        message={"Are you sure you want to delete this rate?"}
      />
    </Grid>
  );
};

export default CommunityRates;
