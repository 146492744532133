import StarsIcon from "@mui/icons-material/Stars";
import { Avatar, Chip, Divider, Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.css";
import Menu from "@mui/material/Menu";
import CssBaseline from "@mui/material/CssBaseline";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import Drawer from "@mui/material/Drawer";

import Typography from "@mui/material/Typography";
import Sidebar from "./Sidebar/Sidebar";
import MiniSidebar from "./Sidebar/MiniSidebar";
import { Auth } from "aws-amplify";

const drawerWidth = 330;

export default function Header({ setProfile }) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [animation, setAnimation] = React.useState(false);
  const user = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const authorized = useSelector((state) => state.authorized);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const componentChange = () => {
    setMobileOpen(false);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    Auth.signOut({global:true})
    setAnchorEl(null);
    dispatch({
      type: "SET_UNAUTHORIZED",
    });
    navigate("/login");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className="appbar_style"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ display: "flex" }}>


          <Typography variant="h1" className="dataRateHeader">
          <img src={`${process.env.PUBLIC_URL}/Datarate_logo_new.svg`} style={{ height: '70px' }} alt="Datarate Logo" />
          </Typography>

          {/*<Box className="chipMainDiv">*/}
          {/*  <Chip*/}
          {/*    className="pointChip"*/}
          {/*    icon={<StarsIcon className="iconStyle" />}*/}
          {/*    label={*/}
          {/*      <div style={{ marginLeft: "2px" }}>*/}
          {/*        <div className="pointsStyle">Points</div>*/}
          {/*        <div className="pointsStyle2">{user ? user.points : 0}</div>*/}
          {/*      </div>*/}
          {/*    }*/}
          {/*  />*/}
          {/*</Box>*/}

          <Divider
            className="headerDivider"
            orientation="vertical"
            variant="middle"
            flexItem
          />

          <div className="profileDiv">
            <div>
              <Avatar
                className="profilePic"
                alt="Profile Picture"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                src={
                  user && user.profile_image_url ? user.profile_image_url : ""
                }
              />
            </div>
            <div className="profileDesc">
              {user && user.first_name ? (
                <div className="userName" onClick={handleClick}>
                  {user.first_name} {user.last_name}{" "}
                </div>
              ) : (
                <div className="userName" onClick={handleClick}>
                  Guest
                </div>
              )}

              {!user && <div className="userName"></div>}
              {/*<div className="proRater" onClick={handleClick}>*/}
              {/*  {user && user.level ? user.level : "New Rater"}*/}
              {/*</div>*/}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    navigate("/profile");
                    setProfile(true);
                  }}
                >
                  Profile
                </MenuItem>
                {authorized && (
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                )}
                {!authorized && (
                  <MenuItem onClick={handleLogout}>Login</MenuItem>
                )}
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Box component="nav" aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          transitionDuration={1000}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          
          <Sidebar setProfile={setProfile} componentChange={componentChange} />
        </Drawer>

        <MiniSidebar setProfile={setProfile} animation={animation} />
      </Box>
    </Box>
  );
}
