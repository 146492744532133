import ChatIcon from "@mui/icons-material/Chat";
import Done from "@mui/icons-material/Done";
import StarIcon from "@mui/icons-material/Star";
import { Avatar, Button, Chip, Divider, Grid, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Link from "@mui/material/Link";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import ChipList from "../common/ChipList";
import CommentItem from "../common/CommentItem";
import StatisticsDialog from "../common/StatisticsDialog";
import RatingObject from "../RatingObject/RatingObject";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { useEffect } from "react";
import axios from "axios";
import { API_URL, popupTimer } from "../../consts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import QrCodeIcon from "@mui/icons-material/QrCode";
import DeleteIcon from '@mui/icons-material/Delete';
import TimelineIcon from '@mui/icons-material/Timeline';
import AddToSpace from "./AddToSpace";
import PathHistoryDialog from "../common/PathHistoryDialog";
import MenuIcon from "@mui/icons-material/Menu";
import Description from "./Description";
import HomeCardDialog from "./HomeCardDialog";
import PublisherComment from "./PublisherComment"

const HomeCardItem = ({
    isFeedback,
    isBookmark,
    displayRemoveFromSpace,
    data,
    searhTags,
    applyMargin,
    onRateSuccessfully,
    displayStars,
    displayStats,
    onRemoveFromSpaceClick,
    showRemove,
    onRemove
}) => {
    const navigate = useNavigate();
    const [showComments, setShowComments] = useState(false);
    const [comments, setComments] = useState([]);
    const [showRateModal, setShowRateModal] = useState(false);
    const [ratingsValue, setRatingsValue] = useState(0);
    const [id, setId] = useState("");
    const [hideMore, setHideMore] = useState(true);
    const [openStatsModal, setOpenStatsModal] = useState(false);
    const [rateId, setRateId] = React.useState(null);
    const [openSpaceModal, setOpenSpaceModal] = useState(false);
    // For Dropdown Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [pathDialogOpen, setPathDialog] = React.useState(false);
    const [openedItemData, setDetailedDataDialog] = React.useState(null);
    const authorized = useSelector((state) => state.authorized);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const getDomain = (url) => {
        const parsedUrl = new URL(url);
        return parsedUrl.hostname;
    }

    const handleLike = (v, i) => {
        setComments([
            ...comments.map((m, index) => {
                if (index === i) return { ...m, liked: m.liked + (v ? +1 : -1) };
                return m;
            }),
        ]);
    };
    const handleSpaceModalClose = () => {
        setOpenSpaceModal(false);
    };
    const addComment = (i) => {
        setComments([
            ...comments.map((m, index) => {
                if (index === i) return { ...m, comments: m.comments + 1 };
                return m;
            }),
        ]);
    };

    const addToBookMarks = () => {
        let url = API_URL + `/bookmarks`;
        if (authorized) {
            axios
                .post(
                    url,
                    { rated_object_id: data.id }

                )
                .then((res) => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "Successfully added to your bookmarks",
                            type: "success",
                        },
                    });
                    setTimeout(() => {
                        dispatch({
                            type: "SET_POPUP",
                            payload: {
                                text: "",
                                type: "",
                            },
                        });
                    }, popupTimer);
                })
                .catch((err) => {
                    if (err.response.status === 401) {
                        dispatch({
                            type: "SET_UNAUTHORIZED",
                        });
                        return;
                    }
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: err.response.data.error
                                ? err.response.data.error
                                : "Something Went Wrong. Please Try Again Later",
                            type: "error",
                        },
                    });
                    setTimeout(() => {
                        dispatch({
                            type: "SET_POPUP",
                            payload: {
                                text: "",
                                type: "",
                            },
                        });
                    }, popupTimer);
                });
        } else {
            dispatch({
                type: "SET_POPUP",
                payload: {
                    text: "You must Login to Perform this Action",
                    type: "error",
                },
            });
            setTimeout(() => {
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: "",
                        type: "",
                    },
                });
            }, popupTimer);
        }
    };
    const onRatingClick = () => {
        setOpenStatsModal(true);
    };
    const showSpacePopup = (rateId) => {
        if (authorized) {
            setRateId(rateId)
            setOpenSpaceModal(true);
        } else {
            showUnauthorizedError();
        }
    };
    const showRatePopup = () => {
        if (authorized) {
            setShowRateModal(true);
            setId(data.id);
        } else {
            showUnauthorizedError();
        }
    };

    const showUnauthorizedError = () => {
        dispatch({
            type: "SET_POPUP",
            payload: {
                text: "You must Login to Perform this Action",
                type: "error",
            },
        });
        setTimeout(() => {
            dispatch({
                type: "SET_POPUP",
                payload: {
                    text: "",
                    type: "",
                },
            });
        }, popupTimer);
    };
    const handleStatsDialogClose = () => {
        setOpenStatsModal(false);
    };
    const handleRatingModalClose = () => {
        setShowRateModal(false);
    };

    const getColorForUrl = (url) => {
        const colors = [
            '#ADD8E6', // Light Blue
            '#20B2AA', // Light Sea Green
            '#5F9EA0', // Cadet Blue
            '#00CED1', // Dark Turquoise
            '#7FFFD4', // Aquamarine
            '#4682B4'  // Steel Blue
        ];

        // Extract the first letter after 'www.' in the URL
        const domain = new URL(url).hostname.replace('www.', '');
        const firstLetter = domain[0].toLowerCase();

        // Get an index based on the first letter's character code
        const colorIndex = firstLetter.charCodeAt(0) % colors.length;

        return colors[colorIndex];
    };
    function formatDateToMinutesOnly(timestamp) {
        const date = new Date(timestamp);

        const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-indexed in JS
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${month}/${day}/${year} ${hours}:${minutes}`;
    }

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Card className="cardItem" sx={{
            display: 'flex', flexDirection: 'column',
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", cursor: 'pointer', height: '100%'
        }}
            onClick={() => { !openSpaceModal && setDetailedDataDialog(data) }}
        >
            {data.image_url ? (
                <div
                    style={{
                        width: '100%',
                        minHeight: '45%',
                        maxHeight: '45%',
                        backgroundImage: `url(${data.image_url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        position: 'relative',
                    }}
                >
                    {/* Menu (top-right) */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                        }}
                    >
                        <Button
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleClick(e);
                            }}
                        >
                            <MenuIcon />
                        </Button>
                    </Box>

                    {/* Website link (left, same row as menu) */}
                    {data.page_id?.href && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '10px',
                                left: '10px',
                                color: 'white',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                padding: '5px 10px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                maxWidth: '70%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                window.open(data.page_id.href, '_blank');
                            }}
                            title={data.page_id?.href} // Shows full URL on hover
                        >
                            {getDomain(data.page_id.href)}
                        </Box>
                    )}
                </div>
            ) : (
                <Box
                    sx={{
                        width: '100%',
                        minHeight: '45%',
                        maxHeight: '45%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: getColorForUrl(data.page_id.href),
                        color: '#fff',
                        fontSize: '32px',
                        fontWeight: 'bold',
                        borderRadius: '4px',
                        position: 'relative',
                    }}
                >
                    {/* Menu (top-right) */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                        }}
                    >
                        <Button
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleClick(e);
                            }}
                        >
                            <MenuIcon />
                        </Button>
                    </Box>

                    {/* Website link (left, same row as menu) */}
                    {data.page_id?.href && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '10px',
                                left: '10px',
                                color: 'white',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                padding: '5px 10px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                maxWidth: '70%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                window.open(data.page_id.href, '_blank');
                            }}
                            title={data.page_id?.href} // Shows full URL on hover
                        >
                            {/* Extract first letter after 'www.' */}
                            {getDomain(data.page_id?.href)}
                        </Box>
                    )}
                    <div >
                        {/* Display first letter of the domain as content */}
                        {data.page_id?.href
                            ? <div style={{fontSize:'60px'}}>{data.page_id.href.split('www.')[1]?.[0]}</div> ||  data.page_id.href
                            : ''}
                    </div>
                </Box>
            )}

            <StatisticsDialog
                data={data.stars}
                total={
                    data.stars &&
                    Object.values(data.stars).reduce(
                        (partial_sum, a, i) => partial_sum + a,
                        0
                    )
                }
                handleClose={handleStatsDialogClose}
                open={openStatsModal}
            />

            <CardHeader
                title={
                    <>
                        <Box>



                            <div
                                className="d-flex"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: '10px'
                                }}
                            >
                                {/* User information on the left */}
                                <Box
                                    className="userName"
                                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                    onClick={() => navigate(`/user/${data.user_id}`)}
                                >
                                    <Avatar
                                        className="profilePic"
                                        alt="Profile Picture"
                                        style={{
                                            maxHeight:'30px',
                                            maxWidth:'30px',

                                            marginRight: '10px'
                                        }}
                                        src={data.profile_image_url}
                                    />
                                    <label>
                                        {data.user_name}
                                    </label>
                                </Box>

                                {/* Stars on the right */}
                                {isBookmark && displayStars && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer'
                                        }}
                                        onClick={onRatingClick}
                                    >
                                        <Rating
                                            style={{ color: "rgba(255, 204, 72, 1)" }}
                                            name="hover-feedback"
                                            value={data.average_stars}
                                            readOnly
                                            emptyIcon={
                                                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                                            }
                                        />
                                    </div>
                                )}
                            </div>


                            {/* New line for comment */}

                            {data.content && (
                                <Box
                                    sx={{
                                        display: "block",
                                        margin: '10px',

                                        width: "100%", // Ensures the comment spans the entire width
                                    }}
                                >
                                    <PublisherComment comment={data.content} onClickDisabled={true} />
                                </Box>
                            )}
                            <div style={{ width: '90%' }}>
                                <ChipList list={data.tags} highlightList={searhTags} AllowChipOverflow={true} />
                            </div>

                        </Box>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    width: "100%",
                                }}
                                className="ratingSection"
                            >



                
                                {isFeedback && data.resolved && (
                                    <div title={'The customer received an answer'}>
                                        <Done className="iconColor done-feedback-sign" color="primary" />
                                    </div>
                                )}
                            </Box>



                            {isFeedback && <TimelineIcon
                                onClick={() => {
                                    setPathDialog(true)
                                }}
                                style={{ color: '#128d6f', 'cursor': 'pointer' }} />
                            }

                        </Box>
                    </>
                }
            />

            <CardContent
                sx={{
                    position: 'relative', // Allows the absolute positioning of "Show More"
                    paddingBottom: '40px', // Add space for the "Show More" link at the bottom
                }}
            >
                {/* <div>
            <Description content={data.content} onClickShowMore={() => setDetailedDataDialog(data)} />
        </div> */}


            </CardContent>

            {isBookmark && (
                <>
                    <CardActions disableSpacing sx={{ flexWrap: "wrap", position: "relative" }}>
                        <Grid item>

                        </Grid>


                        {isFeedback && <Button
                            sx={{
                                width: "76px",
                                height: "28px",
                                textAlign: "center",
                                color: "white",
                                fontWeight: "500",
                                backgroundColor: "#128d6f",
                                borderRadius: "50px",
                                marginLeft: "20px",
                            }}
                            className="button-rate"
                            onClick={() => {
                                showRatePopup();
                            }}
                        >
                            Replay
                        </Button>

                        }


                        <Menu

                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >

                            <MenuItem
                                onClick={handleClose}
                                sx={{ paddingTop: "0", paddingBottom: "0" }}
                            >

                                <Chip
                                    className="viewCount"
                                    icon={<QrCodeIcon className="menuIcon" />}
                                    sx={{ color: "rgba(38, 38, 38, 0.5) !important" }}
                                    label={"Add to space"}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        showSpacePopup(data.id)
                                    }}
                                />

                            </MenuItem>
                            {displayRemoveFromSpace && <MenuItem
                                onClick={handleClose}

                            >
                                <Chip
                                    className="viewCount"
                                    icon={<DeleteIcon className="menuIcon" />}
                                    sx={{ color: "rgba(38, 38, 38, 0.5) !important" }}
                                    label={"Remove from current space"}
                                    onClick={() => onRemoveFromSpaceClick(data.id)}
                                />
                            </MenuItem>
                            }
                            {showRemove && <MenuItem
                                onClick={handleClose}

                            >
                                <Chip
                                    className="viewCount"
                                    icon={<DeleteIcon className="menuIcon" />}
                                    sx={{ color: "rgba(38, 38, 38, 0.5) !important" }}
                                    label={"Remove"}
                                    onClick={() => onRemove(data.id)}
                                />
                            </MenuItem>
                            }

                        </Menu>
                        <Box
                            sx={{
                                marginLeft: applyMargin ? { sm: "0px", md: "auto" } : "auto",
                            }}
                        >



                        </Box>

          

                    </CardActions>


                </>
            )}
            {/* <PathHistoryDialog open={pathDialogOpen}
                    onClose={() => {
                        setPathDialog(false)
                    }}
                    urls={data.last_urls ? data.last_urls : []}
                /> */}
            <RatingObject
                ratings={ratingsValue}
                open={showRateModal}
                isFeedback={true}
                handleClose={handleRatingModalClose}
                id={id}
                onRateSuccessfully={onRateSuccessfully}
            />
            {openSpaceModal ? (
                <AddToSpace
                    open={openSpaceModal}
                    rateId={rateId}
                    handleClose={handleSpaceModalClose}
                    authorized={authorized}
                />
            ) : null}
            {openedItemData && <HomeCardDialog open={openedItemData} data={openedItemData}
                onClose={(e) => {
                    e.stopPropagation()

                    setDetailedDataDialog(null)
                }

                } />
            }
        </Card>
    );
};
HomeCardItem.defaultProps = {
    displayStars: true,
    displayStats: true
}

export default HomeCardItem;
